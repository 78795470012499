<template>
  <div>
    <v-row
      justify="center"
      class="mt-5"
    >
      <v-dialog
        v-model="dialog"
        content-class="white-bg "
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-icon>mdi-plus</v-icon>
            <span
              v-if="promotionObject"
              class="headline"
            >Promote Product</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="promotionObject.content.en.title"
                    label="Title [en]*"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="promotionObject.content.de.title"
                    label="Title [de]*"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="promotionObject.content.fr.title"
                    label="Title [fr]*"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="promotionObject.content.en.sub_title"
                    label="Strapline [en]*"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="promotionObject.content.de.sub_title"
                    label="Strapline [de]*"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="promotionObject.content.fr.sub_title"
                    label="Strapline [fr]*"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="menu_from"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="promotionObject.from"
                        label="From Date"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="promotionObject.from"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="menu_to"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="promotionObject.to"
                        label="To Date"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="promotionObject.to"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-text v-show="hasErrors">
            <v-alert
              outlined
              color="red"
              dense
            >
              <div class="title">
                Alert
              </div>

              <ul>
                <li v-show="!validation.title">
                  en title cannot be empty.
                </li>

                <li v-show="!validation.sub_title">
                  en sub title cannot be empty.
                </li>

                <li v-show="!validation.title_fr">
                  fr title cannot be empty.
                </li>

                <li v-show="!validation.sub_title_fr">
                  fr sub title cannot be empty.
                </li>

                <li v-show="!validation.title_de">
                  de title cannot be empty.
                </li>

                <li v-show="!validation.sub_title_de">
                  de sub title cannot be empty.
                </li>
                <li v-show="!validation.from">
                  date cannot be empty.
                </li>
                <li v-show="!validation.to">
                  body cannot be empty.
                </li>
              </ul>
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="cancel"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="savePromotion"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

  import moment from 'moment'

  export default {
    name: 'Promotions',
    data () {
      return {
        show_promotion_button: false,
        hasErrors: false,
        dialog: false,
        menu_from: false,
        menu_to: false,
        promotion: {

        },
        promotionObject: {
          product: '',
          content: {
            en: {},
            fr: {},
            de: {},
          },
          from: moment().format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
        fromDateMenu: false,
        fromDateVal: null,

        minDate: null,
        maxDate: null,
        promotionsRef: null,
      }
    },
    computed: {
      validation: function () {
        return {
          title: !!this.promotionObject.content.en.title,
          sub_title: !!this.promotionObject.content.en.sub_title,
          title_fr: !!this.promotionObject.content.fr.title,
          sub_title_fr: !!this.promotionObject.content.fr.sub_title,
          title_de: !!this.promotionObject.content.de.title,
          sub_title_de: !!this.promotionObject.content.de.sub_title,
          to: true,
          from: true,
        }
      },
      isValid: function () {
        const validation = this.validation
        return Object.keys(validation).every(function (key) {
          return validation[key]
        })
      },
    },

    mounted () {

    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
    },
    methods: {
      cancel () {
        this.promotion = {}
        this.dialog = false
      },
      updatePromotion (product, promotion = null) {
        this.promotion = promotion
        if (promotion) {
          this.promotionObject = {
            content: {
              en: {
                title: ('en' in promotion.data().content ? promotion.data().content.en.title : ''),
                sub_title: ('en' in promotion.data().content ? promotion.data().content.en.sub_title : ''),
              },
              de: {
                title: ('de' in promotion.data().content ? promotion.data().content.de.title : ''),
                sub_title: ('de' in promotion.data().content ? promotion.data().content.de.sub_title : ''),
              },
              fr: {
                title: ('fr' in promotion.data().content ? promotion.data().content.fr.title : ''),
                sub_title: ('fr' in promotion.data().content ? promotion.data().content.fr.sub_title : ''),
              },
            },
            from: moment.unix(promotion.data().from).format('YYYY-MM-DD'),
            to: moment.unix(promotion.data().to).format('YYYY-MM-DD'),
          }
        }

        this.promotionObject.product = product
        this.dialog = true
      },
      savePromotion () {
        if (this.isValid) {
          const data = {
            from: moment(this.promotionObject.from).unix(),
            to: moment(this.promotionObject.to).unix(),
            content: this.promotionObject.content,
            product: this.promotionObject.product,
          }
          console.log(data)
          if (this.promotion && this.promotion.id) {
            this.promotion.ref.update(
              data,
            )
          } else {
            const promotionsRef = this.$firebaseDatabase.collection('promotions')
            promotionsRef.add(data)
          }
          this.dialog = false
          this.$parent.fetchData()
        } else {
          this.hasErrors = true
        }
      },
    },
  }

</script>
